import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CBtnList,
  LBanquetContact,
  CNewsList,
  CUniqueCard,
  CSpAccordion,
  LUseCase,
  CPostCard,
  CBanquetPlan,
  CBanquetPlanRecommend,
} from '../../components/_index';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';
import banquetPlanGet from '../../utils/banquetPlan-get';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'BANQUET',
            sub: <>宴会・会議</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="l_sect03 u_bgGray">
        <LWrap>
          <p className="c_sectLead">
            東京日本橋の中心というロケーションに加えて、駅に直結する利便性は、あらゆるお集まりに最適です。
            <br />
            最大1,000㎡の大宴会場「ロイヤルホール」をはじめ、中・小の宴会場をご用意しております。
            <br />
            各種パーティー、セミナー、ミーティングまで、ご要望にあったご宴席をご提案いたします。
          </p>
        </LWrap>
      </div>
      <section className="l_sect02">
        <LWrap>
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: 'お知らせ',
            }}
          />
          <CNewsList
            data={infoChoice(infoGet(), ['宴会・会議'], 5)}
            label={false}
          />
          <CBtnList
            data={[
              {
                label: 'お知らせ一覧',
                link: {
                  href: '/information/',
                },
                color: 'borderBlack',
              },
            ]}
          />
        </LWrap>
      </section>
      <div className="l_sect u_pt0">
        <section className="u_mbLarge">
          <LWrap>
            <CSectTitle
              title={{
                en: 'BANQUET',
                ja: '宴会場のご案内',
              }}
            />
            <h3 className="c_headingLine">大宴会場</h3>
            <CUniqueCard
              col={1}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/img_largeVenue.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/banquet/img_largeVenue__sp.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <small>3F</small>ロイヤルホール
                    </>
                  ),
                  text: <>330〜1,000㎡</>,
                  btn: {
                    label: '詳細を見る',
                    link: {
                      href: '/banquet/royal_hall/',
                    },
                  },
                },
              ]}
            />
            <h3 className="c_headingLine">中宴会場</h3>
            <CUniqueCard
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/img_middleVenue.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <small>2F</small>春海
                    </>
                  ),
                  text: <>150〜320㎡</>,
                  btn: {
                    label: '詳細を見る',
                    link: {
                      href: '/banquet/harumi/',
                    },
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_middleVenue02.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <small>2F</small>有明
                    </>
                  ),
                  text: <>82〜325㎡</>,
                  btn: {
                    label: '詳細を見る',
                    link: {
                      href: '/banquet/ariake/',
                    },
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_middleVenue03.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <small>2F</small>東雲
                    </>
                  ),
                  text: <>75〜220㎡</>,
                  btn: {
                    label: '詳細を見る',
                    link: {
                      href: '/banquet/shinonome/',
                    },
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_middleVenue04.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <small>3F</small>クラウンルーム
                    </>
                  ),
                  text: <>130〜260㎡</>,
                  btn: {
                    label: '詳細を見る',
                    link: {
                      href: '/banquet/crown/',
                    },
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_middleVenue05.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <small>4F</small>瑠璃
                    </>
                  ),
                  text: <>177㎡</>,
                  btn: {
                    label: '詳細を見る',
                    link: {
                      href: '/banquet/ruri/',
                    },
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/img_middleVenue06.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <small>20F</small>パラッツオ
                    </>
                  ),
                  text: <>200㎡</>,
                  btn: {
                    label: '詳細を見る',
                    link: {
                      href: '/banquet/palazzo/',
                    },
                  },
                },
              ]}
            />
            <h3 className="c_headingLine u_pc">小宴会場</h3>
            <CSpAccordion title="小宴会場">
              <CUniqueCard
                column={true}
                data={[
                  {
                    img: {
                      src: '/assets/images/banquet/img_smallVenue.png',
                      alt: '',
                    },
                    title: (
                      <>
                        <small>3F</small>ローズルーム
                      </>
                    ),
                    btn: {
                      label: '詳細を見る',
                      link: {
                        href: '/banquet/rose/',
                      },
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/banquet/img_smallVenue02.png',
                      alt: '',
                    },
                    title: (
                      <>
                        <small>4F</small>琥珀
                      </>
                    ),
                    text: <>90㎡</>,
                    btn: {
                      label: '詳細を見る',
                      link: {
                        href: '/banquet/kohaku/',
                      },
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/banquet/img_smallVenue03.png',
                      alt: '',
                    },
                    title: (
                      <>
                        <small>4F</small>霞・彩・宴
                      </>
                    ),
                    text: <>48㎡</>,
                    btn: {
                      label: '詳細を見る',
                      link: {
                        href: '/banquet/kasumi_aya_utage/',
                      },
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/banquet/img_smallVenue04.png',
                      alt: '',
                    },
                    title: (
                      <>
                        <small>4F</small>ブライズルーム
                      </>
                    ),
                    btn: {
                      label: '詳細を見る',
                      link: {
                        href: '/banquet/brides/',
                      },
                    },
                  },
                ]}
              />
            </CSpAccordion>
            <h3 className="c_headingLine u_pc">会議室</h3>
            <CSpAccordion title="会議室">
              <CUniqueCard
                column={true}
                data={[
                  {
                    img: {
                      src: '/assets/images/banquet/img_meetingRoom.png',
                      alt: '',
                    },
                    title: (
                      <>
                        <small>5F</small>ミーティングルーム
                      </>
                    ),
                    text: <>60㎡</>,
                    btn: {
                      label: '詳細を見る',
                      link: {
                        href: '/banquet/meetingroom/',
                      },
                    },
                  },
                ]}
              />
            </CSpAccordion>
          </LWrap>
        </section>
        <LUseCase exClass="u_mb0" />
      </div>
      <section className="l_sect u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle
            title={{
              en: 'PLAN',
              ja: '宴会プラン',
            }}
          />
          <CBanquetPlanRecommend data={banquetPlanGet()} />
          <CBtnList
            data={[
              {
                label: 'プラン一覧',
                link: {
                  href: '/banquet/plan/',
                },
                // color : "bgWhite03"
              },
            ]}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
